.home-root {
  width: calc(100%);
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0rem 0rem;
}

.perks-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  max-width: 400px;
}

.home-badge {
  padding: 0.4rem 0.8rem;
  border-color: #B9B0E1;
  background-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.home-badge-text {
  color: #11007B;
  font-size: 24px;
  font-weight: 500;
}

.perk {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.perk-icon {
  width: 60px;
  height: 50px;
}

.perk-text {
  font-weight: 400;
  font-size: 18px;
  color: var(--title2-color);
}

.plate {
  padding-right: 5px;
}

.toy {
  padding-right: 5px;
}

.home-content {
  /* padding: 8.5rem 10.1rem 0 10.1rem; */
  padding-top: 1rem;
  /* padding-right: 1vh;
  padding-left: 1vh; */
  width: 100%;
  justify-content: center;
  font-family: var(--main-font);
  display: flex;
  flex-direction: column;
  /* max-width: 99rem; */
  margin: 0 auto;
}

.home-section1 {
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: left;
  /* background-image: url('../../assets/videos/desktopvideo.mp4'); */
  width: calc(100% - 8rem);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10rem 4rem;
  position: relative;
}

.home-section1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6); /* White color with 50% opacity */
  z-index: -1; /* Places the overlay behind the content but in front of the video */
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  z-index: -2; /* Places the video behind other content */
}


.home-section1-3 {
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: left;
  background-image: url('../../assets/images/quenta-bg-2.png');
  width: calc(100% - 8rem);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 12rem 4rem;
}

.home-section1-4 {
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: left;
  background-image: url('../../assets/images/quenta-bg-2.png');
  width: calc(100% - 8rem);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 12rem 4rem;
}

.home-section2 {
  padding-top: 70px;
  padding-bottom: 50px;
  justify-content: center;
  
  /* max-width: 99rem; */
  align-items: center;
  display: flex;
  flex-direction: column;
}



.home-section4 {
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  /* max-width: 99rem; */
  align-items: center;
  display: flex;
}

.home-section5 {
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section1-title {
  color: var(--quenta-color);
  font-family: var(--quenta-font);
  font-weight: 500;
  font-size: 75px;
  line-height: 86.25px;
  text-align: left;
}

.carousel {
  position: relative;
  overflow: hidden;
  width: calc(100% + 4rem);
}

.carousel-track-container {
  position: relative;
  height: 100px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.carousel-track-container::before,
.carousel-track-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px; /* Adjust width as needed */
  pointer-events: none;
  z-index: 1;
}

.right-fade1 {
  position: absolute;
  background: linear-gradient(270.25deg, rgba(115, 7, 158, 0.523) 9.28%, rgba(185, 24, 24, 0) 77.07%);;
  right: 0;
  width: 50px;
  height: 545px;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* .carousel-track-container::before {
  left: 0;
  background: linear-gradient(to right, #611187, #831ab4);
}

.carousel-track-container::after {
  right: 0;
  background: linear-gradient(to left, #611187, #611187);
} */

.carousel-track {
  display: flex;
  gap: 100px;
  animation: scroll 45s linear infinite;
  list-style-type: none;
}

.carousel-slide {
  min-width: 150px; /* Same width as the image */
}

.logos-interlude {
  width: 150px;
  height: 70px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.section1-title-3 {
  font-family: var(--quenta-font);
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: var(--quenta-color);
}

.section1-title-4 {
  font-family: var(--quenta-font);
  color: var(--quenta-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
}

.section1-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.section1-title2-3 {
  color: var(--quenta-color);
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

.section1-title2-4 {
  color: var(--quenta-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

.section1-title2 {
  color: var(--quenta-color);
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.section1-title2-left {
  color: var(--quenta-color);
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: justify;
}

.bolder-text {
  font-weight: 600;
}

.section4-box {
  position: relative;
}

.section4-box-background {
  position: absolute;
  z-index: 0;
}

.section1-title2-box {
  color: var(--quenta-color);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  z-index: 99;
  position: absolute;
}

.section1-left {
  /* margin-right: 10px; */
  width:50%;
  /* margin-right: 50px; */
}

.image-grid {
  width: 800px;
  display: flex;
}

.image-grid-mobile {
  display: none;
}

.section1-left-3 {
  /* margin-right: 10px; */
  width:50%;
  /* margin-right: 50px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section1-left-4 {
  /* margin-right: 10px; */
  width:50%;
  /* margin-right: 50px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section1-right {
  /* position:relative; */
}

.home-design-interlude {
  background-color: #611187;
  color: white;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.interlude-title {
  font-family: var(--quenta-font);
  font-size: 35px;
}

.home-design-interlude-images {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100px; /* Adjust height as needed */
}

.section1-main-content {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: left;
  position: relative;
}

.section1-main-content-3 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.section1-main-content-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section1-scroll-message {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.section1-scroll-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.section3-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 57.5px;
  text-align: center;
}

.section3-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.section3-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.section3-left {
  margin-top: 40px;
  margin-left: 2vw;
  width: 100%;
  max-width: 45rem;
}

.section3-right {
  width: 22%;
}

.home-section3 {
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 99rem;
}

.section3-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 250rem;
}

.section4-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section3-scroll-message {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section3-scroll-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.section2-dogcards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
}

.section2-title {
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  color: var(--title-color);
  margin-bottom: 70px;
}

.section2-button {
  /* width: 100px; */
  margin-top: 50px;
}

.section2-button-container {
  display: flex;
  justify-content: center;
}

.section4-left {
  margin-top: 40px;
  padding-right: 10rem;
}

.section4-right {
  margin-left: 3vw;
  margin-top: 11vh;
}

.section4-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 57.5px;
  text-align: center;
}

.section4-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.section5-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 57.5px;
  text-align: center;
}

.section5-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.section5-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.section5-left {
  margin-top: 40px;
  padding-right: 10rem;
}

.section5-right {
}

.section5-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section5-scroll-message {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section5-scroll-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}



/* Media Queries */



/* Mobile devices */
@media (max-width: 600px) {
  .home-root {
    /* padding: 1rem; */
  }

  .home-design-interlude-images {
    height: 60px; /* Adjust height as needed for mobile */
  }

  .carousel-track-container {
    height: 60px; /* Adjust height as needed for mobile */
  }

  .home-badge {
    padding: 0.3rem 0.6rem;
    border-color: #B9B0E1;
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .home-badge-text {
    color: #11007B;
    font-size: 18px;
    font-weight: 500;
  }

  .carousel-track {
    gap: 25px;
  }

  .carousel-slide {
    min-width: 100px;
  }

  .logos-interlude {
    width: 100px;
    height: 40px;
  }

  .perks-container {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .home-content {
    /* padding: 1rem; */
  }

  .home-section1,
  .home-section1-3,
  .home-section1-4 {
    width: calc(100% - 2rem);
    padding: 7.5rem 1rem;
  }



  .section1-title {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }

  .section1-title-3,
  .section1-title-4 {
    font-size: 35px;
    line-height: 45px;
  }

  .section1-title2,
  .section1-title2-3,
  .section1-title2-4 {
    font-size: 20px;
    line-height: 25px;
  }

  .section1-title2-left {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  .section1-buttons {
    flex-direction: column;
    align-items: center;
  }

  .section1-left,
  .section1-left-3,
  .section1-left-4 {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }

  .home-design-interlude {
    padding: 2rem 1rem;
    text-align: center;
  }

  .section3-main-content,
  .section4-main-content,
  .section5-main-content {
    flex-direction: column;
  }

  .section3-left {
    margin-left: 0;
    width: 100%;
  }

  .section3-right {
    width: 100%;
    margin-top: 20px;
  }

  .section4-left {
    padding-right: 1rem;
  }

  .section5-left {
    padding-right: 1rem;
  }

  .interlude-title {
    font-size: 24px;
  }

  .image-grid-mobile {
    display: unset;
    width: 300px;
  }

  .image-grid {
    display: none;
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  .home-root {
    /* padding: 2rem; */
    width: 100%;
  }

  .home-design-interlude-images {
    height: 80px; /* Adjust height as needed for tablets */
  }

  .carousel-track-container {
    height: 80px; /* Adjust height as needed for tablets */
  }

  .carousel-slide {
    min-width: 120px;
  }

  .carousel-track {
    gap: 25px;
  }

  .logos-interlude {
    width: 120px;
    height: 50px;
  }

  .perks-container {
    max-width: 100%;
  }

  .home-content {
    /* padding: 2rem; */
  }

  .home-section1,
  .home-section1-3,
  .home-section1-4 {
    /* width: 100%; */
    /* padding: 3rem 2rem; */
  }

  .section1-title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }

  .section1-title-3,
  .section1-title-4 {
    font-size: 45px;
    line-height: 55px;
  }

  .section1-buttons {
    flex-direction: column;
    align-items: center;
  }

  .section1-left,
  .section1-left-3,
  .section1-left-4 {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }

  .home-design-interlude {
    padding: 3rem 2rem;
    text-align: center;
  }

  .section3-main-content,
  .section4-main-content,
  .section5-main-content {
    flex-direction: column;
  }

  .section1-title2,
  .section1-title2-3,
  .section1-title2-4 {
    font-size: 25px;
    line-height: 30px;
  }

  .section3-left {
    margin-left: 0;
    width: 100%;
  }

  .section3-right {
    width: 100%;
    margin-top: 20px;
  }

  .section4-left {
    padding-right: 2rem;
  }

  .section5-left {
    padding-right: 2rem;
  }

  .interlude-title {
    text-align: center;
  }


}

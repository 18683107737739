.button {
    box-sizing: border-box;
    border-radius: 100px;
    box-shadow: 0px 4px 0px #13064F;
    min-width: 166px;
    width: auto;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 13px 18px;
    justify-content: center;
    gap: 10px;
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 21px;
    border: 2px solid #13064F;
    cursor: pointer;
    /* margin-right: 5px; */
    margin-bottom: 5px;
    white-space: nowrap;
}



.button-small {
    min-width: 0;
    height: fit-content;
    font-size: 16px;
    padding: 3px 18px;
    box-shadow: 0px 3px 0px #13064F;
}

.button-medium {
    min-width: 0;
    height: 45px;
    font-size: 18px;
    padding: 7px 18px;
    box-shadow: 0px 3px 0px #13064F;
}

.button-disabled {
    background-color: rgb(202, 200, 200) !important;
    color: gray !important;
    box-shadow: 0px 4px 0px rgb(165, 162, 162) !important;
    border-color: rgb(167, 164, 164) !important;
}

.button:hover {
    background-color: #5f44d4;
}

.button-normal:hover {
    background-color: #4022BA;
    color: white;
}

.button-filled {
    background-color: #4023C8;
    color: white;
}

.button-normal {
    background-color: white;
    color: #4023C8;
}

a {
    text-decoration: none;
}

@media(max-width: 600px) {
    .button {
        box-sizing: border-box;
        border-radius: 100px;
        box-shadow: 0px 4px 0px #13064F;
        min-width: 166px;
        width: auto;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 7px 14px;
        justify-content: center;
        gap: 10px;
        font-family: var(--main-font);
        font-weight: 500;
        font-size: 16px;
        border: 2px solid #13064F;
        cursor: pointer;
        /* margin-right: 5px; */
        margin-bottom: 5px;
        white-space: nowrap;
    }
}
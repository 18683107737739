:root {
  --primary-color: #53B62D;
  --green-shadow: #3B9419;
  --green-hover: #46A522;
  --green-message: #3A9009;
  --darker-green: #347D19;
  --main-font: 'General Sans', sans-serif;
  --quenta-font: 'Adamina';
  --quenta-color: #11007B;
  --title-color: #1C1C1C;
  --title2-color: #787878;
  --grey-border: #DCDCDC;
  --additional-red: #EA1212; 
  --red-error: #EA1212; 
  --scrollbar-thumb-color: #888888; /* Color of the scrollbar thumb */
  --scrollbar-track-color: #F4F4F4; /* Color of the scrollbar track */
  --scrollbar-thumb-hover-color: #555555; /* Color of the scrollbar thumb on hover */
  --off-white: #f5f4f4;
  --input-disabled: #EBEBEB;
  --grey-labels: #4F5B76;

  /* Spinner */

  --brand-success: #3B9419;
  --loader-size: 3em;
  --check-height: calc(var(--loader-size) / 2);
  --check-width: calc(var(--check-height) / 2);
  --check-left: calc((var(--loader-size) / 8) + (var(--loader-size) / 13));
  --check-thickness: 3px;
  --check-color: var(--brand-success);
}

.ant-btn-primary {
  color: #53B62D !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'DM Sans', 'Adamina', 'General Sans'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
}


body {
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-toast {
  background: red; /* Change the background color to red */
  z-index: -1;
}

.Toastify__progress-bar--info {
  background: var(--primary-color) !important;
}

.Toastify__toast-icon svg {
  fill: var(--primary-color) !important
}

.Toastify__toast-body {
  font-family: var(--main-font);
  font-size: 15px;
  font-weight: 500;
}

/* index.css */

/* Default positioning for desktop */
.Toastify__toast-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

/* Positioning for mobile */
@media screen and (max-width: 768px) {
  .Toastify__toast-container {
    top: 0;
    bottom: initial;
  }
}


